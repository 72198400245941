<template>
  <div class="auth-section">
    <va-progress-bar v-if="loading"
      indeterminate
      :style="{width: '100%', position: 'absolute', bottom: 0, left: 0, backgroundColor: '#ffffff'}"></va-progress-bar>
    <div class="auth-title">Sign in</div>
    <form @submit.prevent="onsubmit" class="ttl-form">
      <va-input
        v-model="login"
        type="name"
        :placeholder="$t(loginLabel)"
        :error="loginError"
        :autosize="false"
      />

      <va-input
        v-model="password"
        type="password"
        :placeholder="$t(passwordLabel)"
        :error="passwordError"
        :autosize="false"
      />

      <div class="btns">
        <va-button type="submit" class="my-0 btn--caps" large :disabled="loading">{{ $t('auth.login') }}</va-button>
        <va-button type="submit" class="my-0 btn--caps btn--white" large>
          <router-link :to="{name: 'recover-password'}">
            {{ $t('auth.recover_password') }}
          </router-link>
        </va-button>
      </div>
    </form>
    <va-modal
      v-model="showExpiredModal"
      :title=" $t('Recover expired') "
      cancelClass="none"
      :okText=" $t('modal.close') "
      :message=" $t('modal.recover.expired') "
      noOutsideDismiss
      noEscDismiss
    />
    <va-modal
      v-model="showExpiredSuccessModal"
      :title=" $t('Recovered') "
      cancelClass="none"
      :okText=" $t('modal.close') "
      :message=" $t('modal.recover.success') "
      noOutsideDismiss
      noEscDismiss
    />
  </div>
</template>

<script>
import store from '../../../store/index';
import { mapGetters } from 'vuex';
import { showToastError } from "../../../services/Helpers/HelperToast";

export default {
  name: 'login',
  data() {
    return {
      login: '',
      loginLabel: 'Login or email',
      passwordLabel: 'auth.password',
      password: '',
      keepLoggedIn: false,
      loginError: false,
      passwordError: false,
      showExpiredModal: false,
      showExpiredSuccessModal: false,
      hasError: false,
      loading: false,
    };
  },
  mounted() {
    if (localStorage.getItem('makeAmazonLogin') === 'true') {
      this.$router.push('/auth/amazon')
    }
    const split = window.location.search.split('?status=');
    if (split[1]) {
      if (split[1] === 'expired') {
        this.showExpiredModal = true;
      }
      if (split[1] === 'recovered') {
        this.showExpiredSuccessModal = true;
      }
    }
  },
  computed: {
    ...mapGetters(['authError']),
    formReady() {
      return !this.loginError.length && !this.passwordError.length;
    },
  },
  methods: {
    onsubmit() {
      this.loading = true;

      if (!this.login.length) {
        this.loginError = true;
        this.loginLabel = 'Email is required';
        this.loading = false;
        return false;
      }

      this.loginError = false;

      if (!this.password.length) {
        this.passwordError = true;
        this.passwordLabel = 'Password is required';
        this.loading = false;
        return false;
      }

      this.passwordError = false;
      this.loginLabel = 'auth.login';
      this.passwordLabel = 'auth.password';

      if (!this.formReady) {
        this.loading = false;
        return false;
      }

      const data = {
        username: this.login,
        password: this.password,
      };

      const tryLogin = store.dispatch('doLogin', data);
      tryLogin.then(() => {
        this.hasError = false;
        this.loginLabel = 'auth.login';
        this.passwordLabel = 'Password is required';
        this.loginError = false;
        this.passwordError = false;
        this.loading = false;
      }).catch(() => {
        this.hasError = true;
        this.passwordLabel = 'Incorrect password';
        this.loginLabel = 'Incorrect login';
        this.loginError = true;
        this.passwordError = true;
        this.loading = false;

        showToastError('Incorrect login or password', this.$toast);
      });
    },
  },
};
</script>

<style lang="scss">
.auth-section {
  .va-progress-bar {
    bottom: auto !important;
    top: 0;
  }
}

.error-message {
  margin-bottom: 1rem;
  background-color: red;
  color: #ffffff;
  padding: 20px;
}

.btns {
  margin-top: 32px;
  * {
    font-family: "Source Sans Pro", "Arial", sans-serif !important;
  }
  button {
    margin-right: 24px;
  }
}

</style>
